<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-clipboard"></i> Asset Type Importer</b-card-title>
			<b-card-sub-title>Imports asset type data in bulk</b-card-sub-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<div fluid class="px-2 mt-4">
				<b-form @submit.stop.prevent="saveOnDatabase" novalidate>
					<div v-show="!importOngoing && !importDone">
						<!-- Asset Type Form -->
						<b-card title="Upload Form">
							<b-row class="mt-2 mb-4 ml-1" no-gutters>
								<b-col sm="10" md="8" lg="6" class="mr-4">
									<b-form-group label="Asset Type Form"
										description="Please select a valid json or csv file for asset type import form format.">
										<b-form-file v-model="file" ref="fileinput" @change="onUploadForm($event)"
											:state="Boolean(file)" placeholder="Choose a JSON or CSV file"></b-form-file>
									</b-form-group>
									<div>
										Download JSON template
										<a :href="jsonTemplateUrl" download="AssetTypeImporter.json">
											here
										</a>
										and CSV template
										<a :href="csvTemplateUrl" download="AssetTypeImporter.csv">
											here.
										</a>
									</div>
								</b-col>
								<b-col sm="1">
									<b-button variant="primary" class="reset-button"
										@click="startAnotherImport()">Reset</b-button>
								</b-col>
							</b-row>
						</b-card>

						<!-- Content Preview -->
						<b-card v-if="!importOngoing && jsonData !== null" title="Content Preview"
							sub-title="Below is the overview of the content of the assetType import form you have selected">
							<br />
							<json-viewer :value="jsonData" />
							<br />
							<b-button variant="primary" @click="onImportData()">Import</b-button>
						</b-card>
					</div>

					<div v-show="importOngoing || importDone">
						<b-row class="my-12">
							<b-col sm="12">
								<b-card title="Import Status" sub-title>
									<div v-if="importErrors.length > 0">
										<p class="card-text">{{ importResultLog }}</p>
										<ul>
											<li v-for="(item, index) in importErrors" :key="index">
												{{ item }}
											</li>
										</ul>
									</div>

									<div v-else>
										<p class="card-text my-4">
											{{ importStatusDisplay }}
										</p>
									</div>

									<span v-show="!importOngoing">
										<b-button variant="primary" @click="startAnotherImport()">
											Start Another Import
										</b-button>
									</span>
								</b-card>
							</b-col>
						</b-row>
					</div>
				</b-form>
			</div>
		</b-card>
	</div>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { ImportUtil } from '@/utils/importUtil';

// API
import dataImporterApi from '@/api/dataImporterApi';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import config from '@/config/env-constants';
let Papa = require('papaparse');
import _ from 'lodash';

export default {
	name: 'asset-type-importer',
	components: { Loading },
	data() {
		return {
			isLoading: false,
			file: null,

			importOngoing: false,
			importDone: false,
			hasError: false,
			importResultLog: '',
			importErrors: [],

			jsonData: null,
			jsonTemplateUrl: '../../assets/files/AssetTypeImporter.json',
			csvTemplateUrl: '../../assets/files/AssetTypeImporter.csv',

			allCompaniesObj: {},
			allAssetTypesObj: {},
			allUOMsObj: {},
			currUserId: this.$store.getters.loggedUser.id,
		};
	},
	computed: {
		importStatusDisplay() {
			let statusDisplay = '';

			if (this.importOngoing) {
				statusDisplay = 'Import In-Progress.';
			} else if (this.hasError) {
				statusDisplay = this.importResultLog;
			} else {
				statusDisplay = 'Import Successful! \n' + this.importResultLog;
			}
			return statusDisplay;
		},
		assetTypeNameRegex() {
			return config.assetTypeNameRegex;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
	},
	mounted() {
		setTimeout(() => {
			try {
				// Filter Access
				if (this.$store.getters.isViewer || this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// Show loader
				this.isLoading = true;

				// Load initial data
				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
				this.allUOMsObj = { ...this.$store.getters.uoms };

			} catch(error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);
	},
	methods: {
		startAnotherImport() {
			this.importDone = false;
			this.importOngoing = false;

			// reset error status
			this.resetErrorStatus();

			// reset form fields
			this.$refs.fileinput.reset();
			this.file = null;
			this.jsonData = null;
		},
		resetErrorStatus() {
			this.hasError = false;
			this.importResultLog = '';
			this.importErrors = [];
		},

		onUploadForm(ev) {
			const file = ev.target.files[0];

			if (this.validateUploadForm(file)) {
				const reader = new FileReader();
				reader.onload = (e) => {
					let extension = file.name.split('.').pop().toLowerCase();
					if (extension === 'csv') {
						const { data } = Papa.parse(e.target.result, {
							header: true,
							skipEmptyLines: true,
							dynamicTyping: true,
							encoding: "UTF-8",
						});

						this.jsonData = this.formatJsonData(data);
					} else {
						this.jsonData = JSON.parse(e.target.result);
						this.jsonData = this.formatJsonData(this.jsonData.assetTypes);
					}
				};

				reader.readAsText(file);
			}
		},
		validateUploadForm(file) {
			let isValid = true;
			const fileTypes = ['csv', 'json'];
			const extension = file.name.split('.').pop().toLowerCase();
			const isAllowed = fileTypes.indexOf(extension) > -1;

			if (!file) {
				this.$toaster.warning('Please select a valid Asset Type Import Form to proceed.');
				isValid = false;
				this.jsonData = null;
			} else if (!isAllowed) {
				this.$toaster.error('Invalid File Type: Please import a valid Asset Type Import Form in JSON or CSV format.');
				isValid = false;
				this.jsonData = null;
			}

			return isValid;
		},
		formatJsonData(params) {
			let results = [];
			params.forEach((param) => {
				param = ImportUtil.trimWhiteSpaces(param);
				results.push(param);
			});

			return {
				assetTypes: results,
			};
		},

		async onImportData() {
			try {
				let assetTypeArr = this.jsonData.assetTypes;
				if (assetTypeArr.length === 0) {
					this.$toaster.warning('At least 1 asset type is required per import.');
					return;
				} else if (assetTypeArr.length > 5000) {
					this.$toaster.warning('Only maximum of 5,000 asset types is allowed per import.');
					return;
				} else if (ImportUtil.hasDuplicates(assetTypeArr, 'name')) {
					let duplicates = ImportUtil.getDuplicates(assetTypeArr, 'name');
					this.$toaster.warning(
						'Duplicates detected! Please address the following assetType(s): ' +
						JSON.stringify(duplicates)
					);
					return;
				} else if (ImportUtil.hasBlankColumnName(assetTypeArr[0])) {
					this.$toaster.warning('Empty Column Name is not allowed. Please check your column names.');
					return;
				} else if (!this.validateDataImport(assetTypeArr)) {
					return;
				}

				await this.saveOnDatabase(this.jsonData);
			} catch (error) {
				this.$toaster.warning('The imported assetType data is invalid and cannot be processed');
			}
		},
		validateDataImport(assetTypes) {
			let isValid = true;
			let errors = [];

			let companies = Object.values({ ...this.allCompaniesObj }).map(company => company.name);
			let uoms = Object.values({ ...this.allUOMsObj }).map(uom => {
				return uom.code + ' (' + uom.name + ')';
			});

			_.forEach(assetTypes, (item, key) => {
				let name = item.name;
				let index = key + 1;
				let errPrefix = (!name || name.length === 0) ? 'Item ' + index : 'Asset Type ' + name;

				if (!name || name.length === 0) {
					errors.push(errPrefix + ' has no name. This is required.');
				} else if ((name && name.length !== 0) && ValidationUtil.objectHasField('name', name, this.allAssetTypesObj)) {
					errors.push('Asset Type "' + name + '" already exists.');
				}

				let regex = new RegExp(this.assetTypeNameRegex);
				if (!regex.test(name)) {
					errors.push(errPrefix + ' has an invalid name. Valid format: ' + this.assetTypeNameRegex);
				}

				if (!item.description || item.description.length === 0) {
					errors.push(errPrefix + ' has no description. This is required.');
				} else if (item.description && item.description.length > 0 && !this.remarksRegex.test(item.description)) {
					errors.push(errPrefix + ' description should only contain letters, numbers and these special characters: &\'"-,/():;!*[]');
				}

				if (!item.company || item.company.length === 0) {
					errors.push(errPrefix + ' has no company. This is required.');
				} else if (item.company && item.company.length !== 0 && !companies.includes(item.company)) {
					errors.push(errPrefix + ' is associated to company, "' + item.company + '", that you don\'t have access to.');
				}

				if (item.uom && item.uom.length !== 0 && !uoms.includes(item.uom)) {
					errors.push(errPrefix + ' has invalid Unit of Measure (UOM), "' + item.uom + '". Please see the list in Asset Type page. i.e. PC (Piece)');
				}
			});

			if (errors.length > 0) {
				isValid = false;

				this.hasError = true;
				this.importErrors = errors;
				this.importResultLog = 'The imported form has error(s).';
				this.importDone = true;
				this.importOngoing = false;
			}

			return isValid;
		},
		async saveOnDatabase(json) {
			try {
				// show loading indicator
				this.isLoading = true;

				this.importOngoing = true;
				this.importDone = false;

				let param = {
					currUserId: this.currUserId,
					currTimeStamp: DateUtil.getCurrentTimestamp(),
					assetTypes: json.assetTypes,
				};

				let { data } = await dataImporterApi.importAssetTypes(param);

				this.hasError = !data.isSuccess;
				this.importErrors = data.errors;
				this.importResultLog = data.message;
				this.importDone = true;
				this.importOngoing = false;

				// update store
				let assetTypesObj = data.assetTypes;
				this.$store.dispatch('updateAllAssetTypes', assetTypesObj);
			} catch (error) {
				this.hasError = true;
				this.importResultLog = error;
				this.importDone = true;
				this.importOngoing = false;
			}

			// hide loading indicator
			this.isLoading = false;
		}
	},
};
</script>